import React from 'react';
import { RequiredFieldComponent } from '../RequiredFiledComponent';

interface ItemViewProps {
  labelName: string;
  value?: any;
  isNotSet?: boolean;
  isUnknown?: boolean;
  isRequired?: boolean;
}

export const ItemViewRow: React.FC<ItemViewProps> = ({
  labelName,
  value,
  isNotSet = false,
  isUnknown = false,
  isRequired = false,
}) => {
  return (
    <>
      {(value || value === 0) && (
        <div className="flex flex-col mt-2 break-words">
          <div className="text-sm font-normal text-gray-800">
            <RequiredFieldComponent
              isRequired={isRequired}
              labelText={`${labelName} `}
            />
          </div>
          <div className="text-base font-normal">{value}</div>
        </div>
      )}
      {(value === undefined || value === null || value?.length === 0) &&
        isNotSet && (
          <div className="flex flex-col mt-2 break-words">
            <div className="text-sm font-normal text-gray-800">
              <RequiredFieldComponent
                isRequired={isRequired}
                labelText={`${labelName} `}
              />
            </div>
            <div className="text-base font-normal">
              {isUnknown ? 'Unknown' : 'Not Set'}
            </div>
          </div>
        )}
    </>
  );
};
