import { SummeryCreateParams } from '../../utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentCreateSteps } from './PaymentCreateSteps';
import { useAppDispatch } from 'hooks/redux';
import { createPayment } from '../../thunks';
import ModalDialog from '@mui/joy/ModalDialog';
import { ModalClose } from '@mui/joy';
import DialogTitle from '@mui/joy/DialogTitle';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Divider from '@mui/joy/Divider';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Button from '@mui/material/Button';
import Modal from '@mui/joy/Modal';
import { getErrorMessage, getModalOpen } from '../../selectors';
import { closeModal } from '../../virtualTerminalSlice';
import { useSelector } from 'react-redux';
import { Base64 } from 'js-base64';
import { useHistory } from 'react-router-dom';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { IconPayment } from '../../../../../components/icons/IconPayment';
import { countryStateList } from '../../../../system/merchants/utils';
import { IconLocation } from '../../../../../components/icons/IconLocation';
import { ItemViewRow } from '../../../../../components/grid/ItemViewRow';

export const SummeryPaymentDetails: React.FC<SummeryCreateParams> = ({
  watch,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history: any = useHistory();
  const isModalOpen = useSelector(getModalOpen);
  const errorMessage = useSelector(getErrorMessage);

  const {
    type,
    merchant,
    program,
    currency,
    amount,
    cardholderName,
    cardNumber,
    expirationDate,
    cVVcVC,
    addressLine1,
    addressLine2,
    city,
    country,
    stateUS,
    state,
    postalZipCode,
  } = watch();

  const submitPayment = async (data: any) => {
    dispatch(
      createPayment({
        programId: data.program?.value,
        merchantId: data.merchant?.value,
        currencyCode: data.currency?.value,
        type: data.type?.value,
        amount: data.amount,
        paymentInstrument: {
          holderName: data.cardholderName,
          number: data.cardNumber.replace(/\D+/g, ''),
          expirationDate: {
            year: `20${expirationDate.slice(-2).trim()}`,
            month: expirationDate.slice(0, 2).trim(),
          },
          billingAddress: {
            line1: data.addressLine1,
            line2: data.line2?.length ? data.line2 : null,
            city: data.city?.length ? data.city : null,
            stateProvince:
              data.country?.value === 'US' ? data.stateUS?.value : data.status,
            countryCode: data.country?.value,
            postalCode: data.postalZipCode,
          },
        },
      })
    )
      .unwrap()
      .then((item: any) =>
        history.push(
          `/transaction-management/virtual-terminal/details/${Base64.encodeURI(item.data.id)}`
        )
      )
      .catch(() => {});
  };

  const handleClickClose = () => {
    dispatch(closeModal());
  };

  return (
    <>
      <form onSubmit={handleSubmit(submitPayment)}>
        <div className="grid grid-cols-8 xxl:grid-cols-12 gap-4">
          <div className="flex col-span-8 xxl:col-span-12 h-auto bg-white border sm:mx-4 my-4 rounded-md">
            <div className="w-full">
              <div className="flex flex-wrap justify-between">
                <div className="-mt-8 w-full sm:w-auto">
                  <div className="flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3 box-bg-medium-aquamarine box-shadow-medium-aquamarine">
                    <div>
                      <AccountBalanceIcon
                        fontSize="large"
                        sx={{ color: '#fff' }}
                      />
                    </div>
                    <div className="text-xs text-white ml-1">
                      Transaction Details
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 items-center justify-center justify-items-start sm:m-2">
                <ItemViewRow
                  labelName={t('virtualTerminal.createDetails.type')}
                  isNotSet
                  isUnknown
                  isRequired
                  value={type?.label}
                />
                <ItemViewRow
                  labelName={t('virtualTerminal.createDetails.merchant')}
                  isNotSet
                  isUnknown
                  isRequired
                  value={merchant?.label}
                />
                <ItemViewRow
                  labelName={t('virtualTerminal.createDetails.program')}
                  isNotSet
                  isUnknown
                  isRequired
                  value={program?.label}
                />
                <ItemViewRow
                  labelName={t('virtualTerminal.createDetails.currency')}
                  isNotSet
                  isUnknown
                  isRequired
                  value={currency?.label}
                />
                <ItemViewRow
                  labelName={t('virtualTerminal.createDetails.amount')}
                  isNotSet
                  isUnknown
                  isRequired
                  value={amount}
                />
              </div>
            </div>
          </div>
          <div className="flex col-span-8 xxl:col-span-12 h-auto bg-white border sm:mx-4 my-4 rounded-md">
            <div className="w-full">
              <div className="flex flex-wrap justify-between">
                <div className="-mt-8 w-full sm:w-auto">
                  <div className="flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3 box-bg-cream-can box-cream-can">
                    <div>
                      <IconPayment />
                    </div>
                    <div className="text-xs text-white ml-1">
                      Payment method details
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 items-center justify-center justify-items-start sm:m-4 ">
                <ItemViewRow
                  labelName={t('virtualTerminal.createDetails.cardholderName')}
                  isNotSet
                  isUnknown
                  isRequired
                  value={cardholderName}
                />
                <ItemViewRow
                  labelName={t('virtualTerminal.createDetails.cardNumber')}
                  isNotSet
                  isUnknown
                  isRequired
                  value={cardNumber}
                />
                <ItemViewRow
                  labelName={t('virtualTerminal.createDetails.expirationDate')}
                  isNotSet
                  isUnknown
                  isRequired
                  value={expirationDate}
                />
                <ItemViewRow
                  labelName={t('virtualTerminal.createDetails.cVVcVC')}
                  isNotSet
                  isUnknown
                  isRequired
                  value={cVVcVC}
                />
              </div>
            </div>
          </div>
          <div className="flex col-span-8 xxl:col-span-12 h-auto bg-white border sm:mx-4 my-4 rounded-md">
            <div className="w-full">
              <div className="flex flex-wrap justify-between">
                <div className="-mt-8 w-full sm:w-auto">
                  <div className="flex flex-row items-center justify-start justify-items-start p-4 w-full sm:w-40 h-16 rounded-md sm:ml-3 box-bg-hippie-blue-300 box-shadow-dark">
                    <div>
                      <IconLocation />
                    </div>
                    <div className="text-xs text-white ml-1">
                      Billing Address
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-4 4xl:grid-cols-5 items-center justify-center justify-items-start sm:m-4">
                <ItemViewRow
                  labelName={t('virtualTerminal.createDetails.addressLine1')}
                  isRequired
                  value={addressLine1}
                />
                <ItemViewRow
                  labelName={t('virtualTerminal.createDetails.addressLine2')}
                  isNotSet
                  value={addressLine2}
                />
                <ItemViewRow
                  labelName={t('virtualTerminal.createDetails.city')}
                  isNotSet
                  isUnknown
                  isRequired
                  value={city}
                />
                {country &&
                  (country?.value === 'US' ||
                    countryStateList.includes(country?.value)) && (
                    <ItemViewRow
                      labelName={t('virtualTerminal.createDetails.state')}
                      isNotSet
                      isUnknown
                      isRequired
                      value={stateUS?.label ? stateUS?.label : state}
                    />
                  )}
                <ItemViewRow
                  labelName={t('virtualTerminal.createDetails.country')}
                  isNotSet
                  isUnknown
                  isRequired
                  value={country?.label}
                />
                <ItemViewRow
                  labelName={t('virtualTerminal.createDetails.postalZipCode')}
                  isNotSet
                  isUnknown
                  isRequired
                  value={postalZipCode}
                />
              </div>
            </div>
          </div>
        </div>
        <PaymentCreateSteps />
      </form>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={isModalOpen}
        onClose={handleClickClose}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 100,
        }}
      >
        <ModalDialog variant="outlined" role="alertdialog">
          <ModalClose onClick={handleClickClose} />
          <DialogTitle>
            <WarningRoundedIcon />
            Payment process error
          </DialogTitle>
          <Divider />

          <DialogContent>
            <div className="w-[15rem] m2d:w-[30rem] xxl:w-[35rem]">
              <p>
                We&#39;re sorry, but we were unable to process your payment at
                this time.
              </p>
              <p className="mt-2">
                Reason returned from Processor: {errorMessage}
              </p>
              <p className="mt-2">
                If you continue to experience issues, please contact our support
                team for assistance.
              </p>
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleClickClose}>
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
};
