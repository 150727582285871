import { useRef } from 'react';

import { ColumnProps, PaginationProps, TableCard } from 'components/table';
import { useAppDispatch } from 'hooks/redux';
import LayoutList from 'layouts/list/LayoutList';
import { AuthorizationItem, ItemSort } from 'entities';

import {
  getFilters,
  getItems,
  getItemsLoadingComputed,
  getPagination,
  getTotalNumber,
} from '../selectors';
import {
  setApplyFilters,
  setPage,
  setRowsPerPage,
  setSort,
} from '../virtualTerminalSlice';
import { useColumns } from './useColumns';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconPayment } from 'components/icons/IconPayment';
import { Filter } from './filters/Filter';
import { LogItem } from '../../../../entities/logs';
import { Base64 } from 'js-base64';
import { UserPermissions } from 'enums/Permissions';
import { getProfilePermission } from 'domain/account/selectors';
import { useSelector } from 'react-redux';
import { isFilterSelected, TerminalFiltersProps } from '../utils';

export const VirtualTerminalList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const items: AuthorizationItem[] = useSelector(getItems);
  const itemsLoadingComputed: boolean = useSelector(getItemsLoadingComputed);
  const pagination: PaginationProps = useSelector(getPagination);
  const totalNumber: number = useSelector(getTotalNumber);
  const permissions: any = useSelector(getProfilePermission);
  const filters: TerminalFiltersProps = useSelector(getFilters);

  const tableRef = useRef<HTMLDivElement>(null);

  const columns: ColumnProps[] = useColumns();

  const setCurrentSort = (sort: ItemSort) => {
    dispatch(setSort(sort));
  };

  const isOnRowClick = permissions.includes(UserPermissions.AUTHORIZATION_GET);

  const handleRowClick = (item: LogItem) => {
    history.push(`virtual-terminal/details/${Base64.encodeURI(item.id)}`);
  };

  const filterAction = isFilterSelected(filters)
    ? () => dispatch(setApplyFilters({}))
    : undefined;

  return (
    <LayoutList title={t('virtualTerminal.breadcrumb')}>
      <TableCard
        tableRef={tableRef}
        loading={itemsLoadingComputed}
        columns={columns}
        totalNumber={totalNumber}
        items={items}
        filters={<Filter />}
        setSort={setCurrentSort}
        onRowClick={isOnRowClick ? handleRowClick : undefined}
        pagination={pagination}
        setPage={setPage}
        filterApply={filterAction}
        create={
          <div className="px-3 py-2 flex-1">
            <Button
              onClick={() => history.push('virtual-terminal/create')}
              className="ml-2"
              variant="contained"
              startIcon={<IconPayment />}
            >
              <span className="text-xxs sm:text-sm">
                {t('virtualTerminal.createPayment')}
              </span>
            </Button>
          </div>
        }
        setRowsPerPage={setRowsPerPage}
      />
    </LayoutList>
  );
};
