import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserDetailsPreview } from 'entities/user-management';
import { RowsPerPage } from 'entities/common';

import {
  defaultFilters,
  FiltersParams,
  LoggingDetails,
  userDetailsPreview,
} from './utils';
import { UserStatus } from '../status';
import {
  activateUser,
  disableUser,
  fetchDetails,
  loadItems,
  loadLoggingItems,
  updateUser,
} from './thunks';
import { ItemSort } from 'entities';
import { User } from './list/context';

export interface MerchantsState {
  items: User[];
  totalNumber: number;
  logsTotalNumber: number;
  userId: string | null;
  itemsLoading: boolean;
  paginationLoading: boolean;
  endReached: boolean;
  loggingEndReached: boolean;
  page: number;
  loggingPage: number;
  rowsPerPage: RowsPerPage;
  loggingRowsPerPage: RowsPerPage;
  downloadLoading: boolean;
  details: UserDetailsPreview;
  loggingDetails: LoggingDetails[];
  loggingDetailsLoading: boolean;
  paginationLoggingDetailsLoading: boolean;
  isDetailsLoading: boolean;
  itemNotFound: boolean;
  filters: FiltersParams;
  sort: ItemSort | null;
  appliedFilters: FiltersParams;
}

export const storeName = 'umUserList';

const initialState: MerchantsState = {
  items: [] as User[],
  userId: null,
  itemsLoading: false,
  paginationLoading: false,
  endReached: false,
  loggingEndReached: false,
  page: 1,
  totalNumber: 0,
  logsTotalNumber: 0,
  loggingPage: 1,
  rowsPerPage: 20 as RowsPerPage,
  loggingRowsPerPage: 20 as RowsPerPage,
  downloadLoading: false,
  itemNotFound: false,
  details: userDetailsPreview,
  loggingDetails: [] as LoggingDetails[],
  loggingDetailsLoading: false,
  paginationLoggingDetailsLoading: false,
  isDetailsLoading: false,
  filters: defaultFilters,
  sort: null,
  appliedFilters: defaultFilters,
};

const merchantsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setLoggingPage(state, action: PayloadAction<number>) {
      state.loggingPage = action.payload;
    },
    setRowsPerPage(state, action: PayloadAction<RowsPerPage>) {
      state.rowsPerPage = action.payload;
      state.page = 1;
      state.items = [];
    },
    setLoggingRowsPerPage(state, action: PayloadAction<RowsPerPage>) {
      state.loggingRowsPerPage = action.payload;
      state.loggingPage = 1;
      state.loggingDetails = [];
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    setApplyFilters(state, action) {
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
      state.appliedFilters = {
        ...state.filters,
        ...(action?.payload || {}),
      };
    },
    resetAllFilters(state) {
      state.filters = initialState.filters;
      state.appliedFilters = initialState.appliedFilters;
    },
    addToUserId(state, action: PayloadAction<string>) {
      state.userId = action.payload;
    },
    resetLoggingItems(state) {
      state.loggingDetails = initialState.loggingDetails;
      state.loggingPage = initialState.loggingPage;
      state.logsTotalNumber = initialState.logsTotalNumber;
    },
    setSort(state, action: PayloadAction<ItemSort>) {
      state.items = initialState.items;
      state.page = initialState.page;
      state.sort = action.payload;
    },
    resetAllItems(state) {
      state.sort = initialState.sort;
      state.items = initialState.items;
      state.page = initialState.page;
      state.rowsPerPage = initialState.rowsPerPage;
      state.filters = initialState.filters;
      state.appliedFilters = initialState.appliedFilters;
      state.totalNumber = initialState.totalNumber;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDetails.fulfilled, (state, action) => {
      state.isDetailsLoading = false;
      state.itemNotFound = false;
      state.details = action.payload;
    });

    builder.addCase(disableUser.fulfilled, (state) => {
      state.details = { ...state.details, status: UserStatus.DISABLED };
    });

    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.details = { ...state.details, ...action.payload };
    });

    builder.addCase(activateUser.fulfilled, (state) => {
      state.details = {
        ...state.details,
        status: UserStatus.ACTIVE,
      };
      state.loggingDetails = initialState.loggingDetails;
    });

    builder.addCase(fetchDetails.pending, (state) => {
      state.details = initialState.details;
      state.isDetailsLoading = true;
      state.loggingDetails = initialState.loggingDetails;
    });

    builder.addCase(fetchDetails.rejected, (state) => {
      state.itemNotFound = true;
      state.isDetailsLoading = false;
      state.loggingDetails = initialState.loggingDetails;
    });

    builder.addCase(
      loadItems.fulfilled,
      (state, action: PayloadAction<any>) => {
        const data = action.payload.data;

        state.items = data;
        state.totalNumber = action.payload.paging.totalCount;
        state.itemsLoading = false;
        state.paginationLoading = false;
        state.endReached =
          data.length === 0 || data.length < state.rowsPerPage * state.page;
      }
    );

    builder.addCase(
      loadLoggingItems.fulfilled,
      (state, action: PayloadAction<any>) => {
        const data = action.payload.data;
        state.loggingDetails = data;
        state.logsTotalNumber = action.payload.paging.totalCount;
        state.loggingDetailsLoading = initialState.loggingDetailsLoading;
        state.paginationLoggingDetailsLoading =
          initialState.paginationLoggingDetailsLoading;
        state.loggingEndReached =
          data.length === 0 ||
          data.length < state.loggingRowsPerPage * state.loggingPage;
      }
    );

    builder.addCase(loadLoggingItems.pending, (state) => {
      state.loggingDetailsLoading = true;
      state.paginationLoggingDetailsLoading = true;
      state.loggingEndReached = true;
    });

    builder.addCase(loadLoggingItems.rejected, (state) => {
      state.loggingDetailsLoading = false;
      state.paginationLoggingDetailsLoading =
        initialState.paginationLoggingDetailsLoading;
      state.loggingDetails = initialState.loggingDetails;
      state.loggingEndReached = initialState.loggingEndReached;
    });

    builder.addCase(loadItems.pending, (state) => {
      state.paginationLoading = true;
      state.itemsLoading = true;
      state.endReached = true;
    });

    builder.addCase(loadItems.rejected, (state) => {
      state.paginationLoading = false;
      state.itemsLoading = false;
      state.items = [];
    });
  },
});

export const {
  setPage,
  setRowsPerPage,
  setLoggingPage,
  setLoggingRowsPerPage,
  setApplyFilters,
  resetAllFilters,
  setSort,
  resetAllItems,
  addToUserId,
  resetLoggingItems,
  setFilters,
} = merchantsSlice.actions;

export default merchantsSlice.reducer;
