import { ItemAttributes } from 'components/itemAttributeType';
import { DateRange } from '../../../types/dateRange';
import moment from 'moment-timezone';
import { dateTimeSecondsFormat } from 'components/dateTimeFormatUtil';

export const details = {
  body: null,
  clientName: null,
  createdTime: null,
  error: null,
  id: '',
  apiGroup: '',
  eventType: '',
  eventId: '',
  merchantName: null,
  programName: null,
  reference: null,
  request: null,
  response: null,
  amount: null,
  terminalId: null,
  cardHolderIp: null,
  responseTime: null,
  transactionId: null,
  transactionType: null,
  type: null,
};

export type ApiFilter = {
  merchantsIds: ItemAttributes[];
  transactionType: ItemAttributes[];
  apiGroup: ItemAttributes[];
  eventType: ItemAttributes[];
  eventId: string | null;
  terminalId: string | null;
  transactionId: string | null;
  cardHolderIp: string | null;
  transactionDate: DateRange | null;
  amount: number | null;
  reference: string | null;
};

export const defaultApiFilter: ApiFilter = {
  merchantsIds: [] as ItemAttributes[],
  transactionType: [] as ItemAttributes[],
  apiGroup: [] as ItemAttributes[],
  eventType: [] as ItemAttributes[],
  terminalId: null,
  eventId: null,
  transactionId: null,
  cardHolderIp: null,
  transactionDate: null,
  amount: null,
  reference: null,
};

export const listRequestPayload = (options: ApiFilter, dateZone: string) => ({
  filters: {
    transactionType:
      options.transactionType?.map(({ value }) => value) || undefined,
    eventType: options.eventType?.map(({ value }) => value) || undefined,
    apiGroup: options.apiGroup.length
      ? options.apiGroup?.map(({ value }) => value)
      : undefined,
    reference: options.reference?.length ? options.reference : undefined,
    amount: options.amount ? options.amount : undefined,
    transactionId: options.transactionId ? options.transactionId : undefined,
    eventId: options.eventId ? options.eventId : undefined,
    cardHolderIp: options.cardHolderIp?.length
      ? options.cardHolderIp
      : undefined,
    merchantsIds: options.merchantsIds?.map(({ value }) => value) || undefined,
    terminalId: options.terminalId?.length ? options.terminalId : undefined,
    transactionDate:
      options.transactionDate?.from && options.transactionDate?.to
        ? {
            from: moment(options.transactionDate.from)
              .tz(dateZone)
              .startOf('day')
              .format(dateTimeSecondsFormat),
            to: moment(options.transactionDate.to)
              .tz(dateZone)
              .endOf('day')
              .format(dateTimeSecondsFormat),
          }
        : undefined,
  },
});

export enum EventType {
  REQUEST = 'Request',
  RESPONSE = 'Response',
  REQUEST_RESPONSE = 'Request Response',
  BACKOFFICE_REQUEST_RESPONSE = 'Backoffice Request Response',
  CREATE_MERCHANT = 'Create Merchant',
  UPDATE_MERCHANT = 'Update Merchant',
  ADD_MERCHANT_CONFIGURATION = 'Add Merchant Configuration',
  CREATE_PROCESSOR = 'Create Processor',
  LIST_AVAILABLE_PROCESSORS = 'List Available Processors',
  LEGAL_ENTITY_SEARCH = 'Legal Entity Search',
  GET_PROCESSOR_REQUEST_SCHEMA = 'Get Processor Request Schema',
  DELETE_PROCESSOR = 'Delete Processor',
  UPDATE_PROCESSOR = 'Update Processor',
  CREATE_PAYMENT_INSTRUMENT = 'Create Payment Instrument',
  GET_RESPONSE_CODES = 'Get Response Codes',
  GET_LAST_CALL = 'Get Last Call',
  GET_PAYMENT_INSTRUMENT = 'Get Payment Instrument',
  GET_TRANSACTION = 'Get Transaction',
  LIST_TRANSACTIONS = 'List Transactions',
  LIST_TIME_ZONES = 'List Time Zones',
  RENEW_API_KEY = 'Renew Api Key',
  NMI_WEBHOOK = 'NMI Webhook',
}
