import { createAsyncThunk } from '@reduxjs/toolkit';

import { listRequestPayload, mappedAuthDetails } from './utils';
import { authorizationsResource } from './api';
import { RootState } from 'store/store';

import { getAuthorizationState, getPagination } from './selectors';
import { PaginationProps } from 'components/table/TableFooter';
import { dateTimeZone, isMerchantSelector } from '../../account/selectors';
import { virtualTerminalResource } from '../virtual-terminal-component/api';

export const loadItems: any = createAsyncThunk(
  'transaction-management/authorizations/loadItems',
  async (_: void, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const { appliedFilters, page, rowsPerPage, sort } = getAuthorizationState(
        getState() as RootState
      );

      const isMerchantRole = isMerchantSelector(getState() as RootState);
      const dateZone = dateTimeZone(getState() as RootState);

      const params = {
        page,
        limit: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
        sort: sort,
        ...listRequestPayload({ filters: appliedFilters }, dateZone),
      };

      return !isMerchantRole
        ? await authorizationsResource.authorizationsList(params)
        : await authorizationsResource.merchantAuthorizationsList(params);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const reloadItems: any = createAsyncThunk(
  'umRolesList/reloadItems',
  async (_: void, thunkApi) => {
    try {
      const { dispatch, getState } = thunkApi;
      const { page, rowsPerPage }: PaginationProps = getPagination(
        getState() as RootState
      );

      dispatch(
        loadItems({
          offset: 0,
          limit: page * rowsPerPage,
        })
      );
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const fetchAuthorizationDetails: any = createAsyncThunk(
  'transaction-management/authorizations/fetchAuthorizationDetails',
  async (data: { id: string }, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const isMerchantRole = isMerchantSelector(getState() as RootState);

      const response = !isMerchantRole
        ? await authorizationsResource.fetchDetails(data)
        : await authorizationsResource.fetchMerchantDetails(data);

      return mappedAuthDetails(response);
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export const markAsRefundThunk: any = createAsyncThunk(
  'transaction-management/authorizations/markAsRefund',
  async (data: { id: string; amount: number }) => {
    try {
      return await virtualTerminalResource.refundPayment(data);
    } catch (e: any) {
      return Promise.reject(e.error);
    }
  }
);

export const captureProcess: any = createAsyncThunk(
  'transaction-management/authorizations/captureProcess',
  async (data: { id: string }) => {
    try {
      return await virtualTerminalResource.capturePayment(data);
    } catch (e: any) {
      return Promise.reject(e.error);
    }
  }
);

export const voidProcess: any = createAsyncThunk(
  'transaction-management/authorizations/voidProcess',
  async (data: { id: string }) => {
    try {
      return await virtualTerminalResource.voidPayment(data);
    } catch (e: any) {
      return Promise.reject(e.error);
    }
  }
);

export const markAsVoidThunk: any = createAsyncThunk(
  'transaction-management/authorizations/markAsVoid',
  async (data: { id: string }, thunkApi) => {
    try {
      const response = await authorizationsResource.voidAuthorizations(data);

      thunkApi.dispatch(reloadItems());

      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);
