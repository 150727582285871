import { useMemo } from 'react';
import { CellBase, CellLight, ColumnProps } from 'components/table/Table';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { dateTimeFormat, dateTimeZone } from 'domain/account/selectors';
import {
  dateFormatUtil,
  dateTimeFormatUtil,
  timeFormatUtil,
} from 'components/dateTimeFormatUtil';
import moment from 'moment/moment';
import { AuthorizationItem, AuthorizationStatus } from '../../../../entities';
import { AuthorizationStatusBadge } from '../../authorizations/list/AuthorizationStatusBadge';
import numeral from 'numeral';

export const useColumns = () => {
  const { t } = useTranslation();
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);

  const zone = useMemo(
    () => (dateZone ? dateZone : moment.tz.guess()),
    [dateZone]
  );

  const format = useMemo(
    () => (dateFormat ? dateFormat : dateFormatUtil),
    [dateFormat]
  );

  const timeFormat = (value: string) =>
    dateTimeFormatUtil(value, format + timeFormatUtil, zone);

  return useMemo(
    (): ColumnProps[] => [
      {
        id: 'transmissionId',
        value: (item: AuthorizationItem) => <CellLight>{item.id}</CellLight>,
        label: t('transactionsManagement.authorizations.list.transactionId'),
        width: 280,
      },
      {
        id: 'transmissionDate',
        value: (item: AuthorizationItem) => (
          <CellLight>
            {item.transmissionDate && timeFormat(item.transmissionDate)}
          </CellLight>
        ),
        label: t('virtualTerminal.filterPayments.submittedDate'),
        isSorted: true,
        sortField: 'AUTHORIZATION_TRANSMISSION_TIME',
        width: 220,
      },
      {
        id: 'settledDate',
        value: (item: AuthorizationItem) => (
          <CellLight>
            {item.settledDate && timeFormat(item.settledDate)}
          </CellLight>
        ),
        label: t('virtualTerminal.filterPayments.settledDate'),
        isSorted: true,
        sortField: 'AUTHORIZATION_SETTLED_DATE',
        width: 220,
      },
      {
        id: 'transactionStatus',
        value: (item: AuthorizationItem) => (
          <CellLight>
            {item.status && (
              <AuthorizationStatusBadge
                widthClass={'w-20'}
                status={item.status}
              >
                {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  Object.values(AuthorizationStatus).includes(item.status)
                    ? t(
                        `transactionsManagement.authorizations.status.${item.status}`
                      )
                    : item.status
                }
              </AuthorizationStatusBadge>
            )}
          </CellLight>
        ),
        label: t('virtualTerminal.filterPayments.status'),
        isSorted: true,
        sortField: 'AUTHORIZATION_STATUS',
        width: 140,
      },
      {
        id: 'transactionType',
        value: (item: AuthorizationItem) => (
          <CellBase>
            {item.transactionType &&
              t(
                `transactionsManagement.authorizations.transactionType.${item.transactionType}`
              )}
          </CellBase>
        ),
        label: t('transactionsManagement.transactions.list.transactionType'),
        isSorted: true,
        sortField: 'AUTHORIZATION_TYPE',
        width: 100,
      },
      {
        id: 'transactionAmount',
        value: (item: AuthorizationItem) => (
          <CellBase>{numeral(item.transactionAmount).format('0.00')}</CellBase>
        ),
        label: t(
          'transactionsManagement.authorizations.list.transactionAmount'
        ),
        isSorted: true,
        sortField: 'AUTHORIZATION_AMOUNT',
        width: 100,
      },
      {
        id: 'maskedPan',
        value: (item: AuthorizationItem) => (
          <CellBase>{item.cardNumber}</CellBase>
        ),
        label: t('transactionsManagement.authorizations.list.maskedPan'),
        isSorted: true,
        sortField: 'AUTHORIZATION_MASKED_PAN',
        width: 125,
      },
      {
        id: 'submittedBy',
        value: (item: AuthorizationItem) => (
          <CellLight>{item.submittedBy}</CellLight>
        ),
        label: t('transactionsManagement.transactions.list.submittedBy'),
        isSorted: false,
        sortField: 'SUBMITTED_BY',
        width: 120,
      },
      {
        id: 'merchantName',
        value: (item: AuthorizationItem) => (
          <CellLight>{item.merchantName}</CellLight>
        ),
        label: t('transactionsManagement.transactions.list.subMerchantName'),
        isSorted: true,
        sortField: 'MERCHANT_NAME',
        width: 220,
      },
    ],
    [t]
  );
};
