import { ItemAttributes } from 'components/itemAttributeType';
import { enumKeyValue } from 'utils/utilClass';

export enum KeyStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export enum KeyTypes {
  ACQUIRING = 'Acquiring',
  BOARDING = 'Onboarding',
}

export const enumValuesToObjects = (): { value: any; label: string }[] => {
  return Object.values(KeyTypes).map((val) => ({
    value: enumKeyValue(KeyTypes, val),
    label: val,
  }));
};

export const keyStatusItems: ItemAttributes[] = [
  { label: KeyStatus.ACTIVE, value: KeyStatus.ACTIVE },
  { label: KeyStatus.DISABLED, value: KeyStatus.DISABLED },
];

export type FiltersParams = {
  companyName: string | null;
  keyPrefix: string | null;
  businessName: string | null;
  enabled: ItemAttributes[];
  type: ItemAttributes[];
};

export const defaultFilters: FiltersParams = {
  companyName: null,
  keyPrefix: null,
  businessName: null,
  enabled: [] as ItemAttributes[],
  type: [] as ItemAttributes[],
};

const isActiveStatus = (status: string) => status === KeyStatus.ACTIVE;

export const listRequestPayload = (options: FiltersParams) => ({
  enabled:
    options.enabled?.map(({ value }) => isActiveStatus(value)) || undefined,
  type: options.type?.map(({ value }) => value) || undefined,
  keyPrefix: options.keyPrefix || undefined,
  companyName: options.companyName || undefined,
  businessName: options.businessName || undefined,
});

export type SecretKeyItem = {
  companyName: string;
  keyPrefix: string;
  businessName: string;
  type: string;
  isEnabled: boolean;
  lastUsed: string;
  lastUpdatedTime: string;
};
