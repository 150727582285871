import api from 'api';

export const listApiGroup = () => {
  return api.post('/dropdown/api-group');
};

export const listAuditEventType = () => {
  return api.post('/dropdown/audit-event-type');
};

export const apiGroupList = async () => {
  try {
    const { data } = await listApiGroup();
    return data;
  } catch (e) {
    return [];
  }
};

export const auditEventType = async () => {
  try {
    const { data } = await listAuditEventType();
    return data;
  } catch (e) {
    return [];
  }
};
