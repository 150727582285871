import { useMemo } from 'react';
import { CellLight, ColumnProps } from 'components/table/Table';
import { MerchantToggleStatusBadge } from './MerchantToggleStatusBadge';
import { useSelector } from 'react-redux';
import { dateTimeFormat, dateTimeZone } from 'domain/account/selectors';
import {
  dateTimeFormatUtil,
  timeFormatUtil,
} from 'components/dateTimeFormatUtil';

export type MerchantItem = {
  id: string;
  name: string;
  status: string;
  accountHolderFirstName: string;
  accountHolderLastName: string;
  companyName: string;
  processor: string;
  programName: string;
  lastActivity: string;
};

export const useColumns = () => {
  const dateFormat = useSelector(dateTimeFormat);
  const dateZone = useSelector(dateTimeZone);
  const timeFormat = (value: string) =>
    dateTimeFormatUtil(value, dateFormat + timeFormatUtil, dateZone);

  return useMemo(
    (): ColumnProps[] => [
      {
        id: 'name',
        label: 'Merchant',
        value: (item: MerchantItem) => <CellLight>{item.name}</CellLight>,
        isSorted: true,
        width: 150,
        sortField: 'MERCHANT_NAME',
      },
      {
        id: 'isEnabled',
        label: 'Status',
        value: (item: MerchantItem) => (
          <CellLight>
            <MerchantToggleStatusBadge status={item.status}>
              {item.status}
            </MerchantToggleStatusBadge>
          </CellLight>
        ),
        isSorted: false,
        width: 150,
        sortField: 'MERCHANT_STATUS',
      },
      {
        id: 'processor',
        label: 'Processor',
        value: (item: MerchantItem) => (
          <CellLight>{item.processor.replace('_', ' ')}</CellLight>
        ),
        isSorted: true,
        width: 150,
        sortField: 'MERCHANT_PROCESSOR',
      },
      {
        id: 'accountHolder',
        value: (item: MerchantItem) => (
          <CellLight>
            {item.accountHolderFirstName} {item.accountHolderLastName}
          </CellLight>
        ),
        label: 'Account Holder',
        width: 150,
        isSorted: true,
        sortField: 'MERCHANT_ACCOUNT_HOLDER',
      },
      {
        id: 'companyName',
        label: 'Company',
        value: (item: MerchantItem) => (
          <CellLight>{item.companyName}</CellLight>
        ),
        isSorted: true,
        sortField: 'MERCHANT_COMPANY',
      },
      {
        id: 'programName',
        label: 'Program',
        value: (item: MerchantItem) => (
          <CellLight>{item.programName}</CellLight>
        ),
        isSorted: true,
        width: 150,
        sortField: 'PROGRAM_NAME',
      },
      {
        id: 'lastActivity',
        label: 'Last Activity',
        value: (item: MerchantItem) => (
          <CellLight>{timeFormat(item.lastActivity)}</CellLight>
        ),
        isSorted: true,
        width: 200,
        sortField: 'MERCHANT_ACTIVITY',
      },
    ],
    []
  );
};
