import { Input } from 'components/inputs';
import React, { useState } from 'react';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import { MerchantCreateSteps } from './MerchantCreateSteps';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getMerchantNMIItemStatus } from '../selectors';
import { MerchantNMIItem } from 'entities/system';
import { setMerchantNMICreate } from '../merchantsSlice';
import {
  accountHolderTypeList,
  accountTypeList,
  conditionResultList,
  localeList,
  merchantTypeList,
  timeZoneList,
} from '../utils';
import { userNameNMIPattern } from 'validation/patterns';
import { SelectNoController } from '../../../../components/inputs/SelectNoController';

export const MerchantNMIComponent = () => {
  const dispatch = useAppDispatch();
  const merchantNMIItem = useAppSelector(getMerchantNMIItemStatus);
  const [nmiInfo, setNMIInfo] = useState<MerchantNMIItem>(merchantNMIItem);

  const setNmiValue = (value: any, name: string) => {
    setNMIInfo({
      ...nmiInfo,
      [name]: value,
    });
  };

  const setNMI = () => dispatch(setMerchantNMICreate(nmiInfo));

  const isDisabled =
    !nmiInfo.username.trim().length ||
    !userNameNMIPattern.test(nmiInfo.username) ||
    !nmiInfo.routingNumber ||
    !nmiInfo.routingNumber.trim().length ||
    !nmiInfo.feePlan.trim().length ||
    !nmiInfo.timezoneId;

  return (
    <>
      <div className="grid gap-3 sm:grid-cols-2 l2g:grid-cols-3 3xl:grid-cols-4 items-center justify-items-start my-4 sm:m-4 sm:p-3">
        <div className="w-full">
          <Input
            label={
              <RequiredFieldComponent
                isRequired={false}
                labelText="Account Number"
              />
            }
            placeholder="Account Number"
            onChange={(val) =>
              setNmiValue(val.currentTarget.value.trim(), 'accountNumber')
            }
            defaultValue={
              merchantNMIItem.accountNumber ? merchantNMIItem.accountNumber : ''
            }
            labelFontClassName="text-sm font-normal"
          />
        </div>
        <div className="w-full">
          <Input
            label={
              <RequiredFieldComponent
                isRequired={true}
                labelText="Routing Number"
              />
            }
            placeholder="Routing Number"
            onChange={(val) =>
              setNmiValue(val.currentTarget.value.trim(), 'routingNumber')
            }
            defaultValue={
              merchantNMIItem.routingNumber ? merchantNMIItem.routingNumber : ''
            }
            labelFontClassName="text-sm font-normal"
          />
        </div>
        <div className="w-full">
          <Input
            label={
              <RequiredFieldComponent
                isRequired={true}
                labelText="Username"
                infoIconMessage="Username can't contain spaces or any special caracters, except dots"
              />
            }
            placeholder="Username"
            onChange={(val) =>
              setNmiValue(val.currentTarget.value.trim(), 'username')
            }
            defaultValue={merchantNMIItem.username}
            labelFontClassName="text-sm font-normal"
            hasError={
              merchantNMIItem.username !== '' &&
              !userNameNMIPattern.test(merchantNMIItem.username)
            }
          />
        </div>
        <div className="w-full">
          <Input
            label={
              <RequiredFieldComponent
                isRequired={true}
                labelText="Fee Plan"
                infoIconMessage="Use an existing NMI Fee Plan"
              />
            }
            placeholder="Fee Plan"
            onChange={(val) =>
              setNmiValue(val.currentTarget.value.trim(), 'feePlan')
            }
            defaultValue={merchantNMIItem.feePlan}
            labelFontClassName="text-sm font-normal"
          />
        </div>
        <div className="w-full">
          <SelectNoController
            label={
              <RequiredFieldComponent
                labelText="Locale"
                isRequired={false}
                className="font-medium tracking-wide text-xs text-black"
              />
            }
            height="2.2rem"
            placeholder="Locale"
            name="locale"
            onChange={(val) => setNmiValue(val, 'locale')}
            options={localeList}
            value={nmiInfo.locale?.value ? nmiInfo.locale : null}
          />
        </div>
        <div className="w-full">
          <SelectNoController
            label={
              <RequiredFieldComponent
                isRequired={true}
                className="font-medium tracking-wide text-xs text-black"
                labelText="Timezone Id"
              />
            }
            height="2.2rem"
            placeholder="Timezone Id"
            name="timezoneId"
            onChange={(val) => setNmiValue(val, 'timezoneId')}
            options={timeZoneList}
            value={nmiInfo.timezoneId?.value ? nmiInfo.timezoneId : null}
          />
        </div>
        <div className="w-full">
          <SelectNoController
            label={
              <RequiredFieldComponent
                labelText="Account Type"
                isRequired={false}
                className="font-medium tracking-wide text-xs text-black"
              />
            }
            height="2.2rem"
            placeholder="Account Type"
            name="accountType"
            onChange={(val) => setNmiValue(val, 'accountType')}
            options={accountTypeList}
            value={nmiInfo?.accountType?.value ? nmiInfo.accountType : null}
          />
        </div>
        <div className="w-full">
          <SelectNoController
            label={
              <RequiredFieldComponent
                labelText="Account Holder Type"
                isRequired={false}
                className="font-medium tracking-wide text-xs text-black"
              />
            }
            height="2.2rem"
            placeholder="Account Type"
            name="accountHolderType"
            onChange={(val) => setNmiValue(val, 'accountHolderType')}
            options={accountHolderTypeList}
            value={
              nmiInfo.accountHolderType?.value
                ? nmiInfo.accountHolderType
                : null
            }
          />
        </div>
        <div className="w-full">
          <SelectNoController
            label={
              <RequiredFieldComponent
                labelText="Merchant Type"
                isRequired={false}
                className="font-medium tracking-wide text-xs text-black"
              />
            }
            height="2.2rem"
            placeholder="Merchant Type"
            name="merchantType"
            onChange={(val) => setNmiValue(val, 'merchantType')}
            options={merchantTypeList}
            value={nmiInfo.merchantType?.value ? nmiInfo.merchantType : null}
          />
        </div>
        <div className="w-full">
          <SelectNoController
            label={
              <RequiredFieldComponent
                labelText="Capture Higher Than Authed"
                isRequired={false}
                className="font-medium tracking-wide text-xs text-black"
              />
            }
            height="2.2rem"
            placeholder="Capture Higher Than Authed"
            name="captureHigherThanAuthed"
            onChange={(val) => setNmiValue(val, 'captureHigherThanAuthed')}
            options={conditionResultList}
            value={
              nmiInfo.captureHigherThanAuthed?.value
                ? nmiInfo.captureHigherThanAuthed
                : null
            }
          />
        </div>
        <div className="w-full">
          <SelectNoController
            label={
              <RequiredFieldComponent
                labelText="Allow Payment Facilitator Fields"
                isRequired={false}
                className="font-medium tracking-wide text-xs text-black"
              />
            }
            placeholder="Allow Payment Facilitator Fields"
            name="captureHigherThanAuthed"
            onChange={(val) =>
              setNmiValue(val, 'allowPaymentFacilitatorFields')
            }
            height="2.2rem"
            options={conditionResultList}
            value={
              nmiInfo.allowPaymentFacilitatorFields?.value
                ? nmiInfo.allowPaymentFacilitatorFields
                : null
            }
          />
        </div>
      </div>
      <MerchantCreateSteps isNextStepDisabled={isDisabled} onClick={setNMI} />
    </>
  );
};
