import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RowsPerPage } from 'entities/common';

import { createPayment, findCardBrand, loadItems } from './thunks';
import { AuthorizationItem, ItemSort } from 'entities';
import { defaultFilters, TerminalFiltersProps } from './utils';
import { mappedData } from '../authorizations/utils';

export interface VirtualTerminalState {
  items: AuthorizationItem[];
  itemsLoading: boolean;
  createPaymentLoading: boolean;
  isModalOpen: boolean;
  isCreatedPayment: boolean;
  errorMessage: string;
  paymentStep: number;
  currentNotificationId: any;
  paginationLoading: boolean;
  endReached: boolean;
  page: number;
  totalNumber: number;
  rowsPerPage: RowsPerPage;
  itemNotFound: boolean;
  isCardBrandLoading: boolean;
  cardBrand: string;
  isCardBrandNotFound: boolean;
  filters: TerminalFiltersProps;
  sort: ItemSort | null;
  appliedFilters: TerminalFiltersProps;
}

export const storeName = 'virtualTerminal';

const initialState: VirtualTerminalState = {
  items: [] as AuthorizationItem[],
  itemsLoading: false,
  createPaymentLoading: false,
  isModalOpen: false,
  isCreatedPayment: false,
  errorMessage: '',
  paymentStep: 1,
  currentNotificationId: null,
  paginationLoading: false,
  endReached: false,
  page: 1,
  totalNumber: 0,
  rowsPerPage: 20 as RowsPerPage,
  itemNotFound: false,
  isCardBrandLoading: false,
  cardBrand: '',
  isCardBrandNotFound: false,
  filters: defaultFilters,
  sort: null,
  appliedFilters: defaultFilters,
};

const virtualTerminalSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setRowsPerPage(state, action: PayloadAction<RowsPerPage>) {
      state.rowsPerPage = action.payload;
      state.page = initialState.page;
      state.items = initialState.items;
      state.totalNumber = initialState.totalNumber;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },
    setApplyFilters(state, action) {
      state.appliedFilters = {
        ...state.filters,
        ...(action?.payload || {}),
      };
      state.items = initialState.items;
      state.page = initialState.page;
      state.totalNumber = initialState.totalNumber;
    },
    resetAllFilters(state) {
      state.filters = initialState.filters;
      state.appliedFilters = initialState.appliedFilters;
      state.totalNumber = initialState.totalNumber;
      state.page = initialState.page;
    },
    upPaymentCreateStep(state) {
      state.paymentStep = state.paymentStep + 1;
    },

    downPaymentCreateStepNumber(state) {
      state.paymentStep = state.paymentStep - 1;
    },

    clearPaymentCreate(state) {
      state.paymentStep = initialState.paymentStep;
    },
    closeModal(state) {
      state.isModalOpen = initialState.isModalOpen;
      state.paymentStep = initialState.paymentStep;
    },
    setSort(state, action: PayloadAction<ItemSort>) {
      state.items = initialState.items;
      state.page = initialState.page;
      state.sort = action.payload;
      state.totalNumber = initialState.totalNumber;
    },
    resetAllItems(state) {
      state.sort = initialState.sort;
      state.items = initialState.items;
      state.page = initialState.page;
      state.filters = initialState.filters;
      state.appliedFilters = initialState.appliedFilters;
      state.totalNumber = initialState.totalNumber;
      state.paymentStep = initialState.paymentStep;
      state.isModalOpen = initialState.isModalOpen;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadItems.fulfilled,
      (
        state,
        action: PayloadAction<{
          data: AuthorizationItem[];
          paging: { totalCount: number };
        }>
      ) => {
        const data = action.payload.data;

        state.items = mappedData(data);
        state.totalNumber = action.payload.paging.totalCount;
        state.itemsLoading = false;
        state.paginationLoading = false;
        state.endReached =
          data.length === 0 || data.length < state.rowsPerPage * state.page;
      }
    );

    builder.addCase(loadItems.pending, (state) => {
      state.paginationLoading = true;
      state.itemsLoading = true;
      state.endReached = true;
    });

    builder.addCase(loadItems.rejected, (state) => {
      state.paginationLoading = false;
      state.itemsLoading = false;
      state.items = [];
    });
    builder.addCase(createPayment.fulfilled, (state) => {
      state.createPaymentLoading = false;
      state.isModalOpen = false;
      state.isCreatedPayment = true;
    });

    builder.addCase(createPayment.pending, (state) => {
      state.createPaymentLoading = true;
      state.isModalOpen = false;
      state.isCreatedPayment = false;
    });

    builder.addCase(createPayment.rejected, (state, action: any) => {
      const { error } = action;
      state.errorMessage = error?.message ? error.message : error?.code;
      state.createPaymentLoading = false;
      state.isModalOpen = true;
      state.isCreatedPayment = false;
    });

    builder.addCase(findCardBrand.fulfilled, (state, action: any) => {
      state.cardBrand = action.payload;
      state.isCardBrandLoading = initialState.isCardBrandLoading;
      state.isCardBrandNotFound = initialState.isCardBrandNotFound;
    });

    builder.addCase(findCardBrand.pending, (state) => {
      state.cardBrand = initialState.cardBrand;
      state.isCardBrandLoading = true;
      state.isCardBrandNotFound = initialState.isCardBrandNotFound;
    });

    builder.addCase(findCardBrand.rejected, (state) => {
      state.cardBrand = initialState.cardBrand;
      state.isCardBrandLoading = initialState.isCardBrandLoading;
      state.isCardBrandNotFound = true;
    });
  },
});

export const {
  setPage,
  setRowsPerPage,
  setApplyFilters,
  resetAllFilters,
  setSort,
  resetAllItems,
  upPaymentCreateStep,
  downPaymentCreateStepNumber,
  clearPaymentCreate,
  closeModal,
  setFilters,
} = virtualTerminalSlice.actions;

export default virtualTerminalSlice.reducer;
