import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useSelector } from 'react-redux';
import { getAppliedFilters, getFilters } from '../../selectors';
import { useForm } from 'react-hook-form';
import { resetAllFilters, setApplyFilters, setFilters } from '../../apiSlice';
import { ApiFilter, defaultApiFilter } from '../../utils';
import { Input } from 'components/inputs';
import { FilterSelect } from 'components/filters/FilterSelect';
import { merchantList } from 'api/merchants';
import { ItemAttributes } from 'components/itemAttributeType';
import { getTransactionTypes } from 'domain/transaction-management/authorizations/list/filters/utils';
import { RequiredFieldComponent } from 'components/RequiredFiledComponent';
import { getProfilePermission } from 'domain/account/selectors';
import { UserPermissions } from 'enums/Permissions';
import React, { useRef } from 'react';
import { useFilterEffect } from 'utils/useFilterEffect';
import {
  DatePickerControl,
  DatePickerType,
  setDateFilter,
} from 'components/DatePickerControl';
import { FilterObjectWrapper } from 'components/filters/FilterObjectWrapper';
import { apiGroupList, auditEventType } from '../../../../../api/apiLogs';

type FiltersProps = {
  toggleDrawer?: () => void;
};

export const Filters: React.FC<FiltersProps> = ({ toggleDrawer }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const appliedFilters: ApiFilter = useSelector(getAppliedFilters);
  const filters: ApiFilter = useSelector(getFilters);
  const transactionTypes: ItemAttributes[] = getTransactionTypes(t);
  const permissions: any = useAppSelector(getProfilePermission);
  const hasMerchantList = permissions.includes(UserPermissions.MERCHANT_LIST);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<any>({ mode: 'onBlur', defaultValues: appliedFilters });

  const allValues = watch();
  const previousValues = useRef<ApiFilter>(appliedFilters);

  useFilterEffect(previousValues, allValues, setFilters);

  const resetFilter = () => {
    dispatch(resetAllFilters());
    reset(defaultApiFilter);
  };

  const handleApplyFilters = () => {
    dispatch(setApplyFilters({}));
    if (toggleDrawer) toggleDrawer();
  };

  const apiHasFilter = [
    filters.merchantsIds.length ||
      filters.transactionType.length ||
      filters.eventType.length ||
      filters.apiGroup.length ||
      filters.eventId?.length ||
      filters.terminalId?.length ||
      filters.transactionId?.length ||
      filters.cardHolderIp?.length ||
      filters.amount ||
      (filters.transactionDate?.from && filters.transactionDate?.to) ||
      filters.reference?.length,
  ].some((value) => !!value);

  const handleDateChange = (callback: any, dates: any) => {
    const [start, end] = dates;
    if (start) {
      setDateFilter(callback, {
        from: start,
        to: null,
      });
    }

    if (start && end) {
      setDateFilter(callback, {
        from: start,
        to: end,
      });
    }
  };

  const filterComponentsList = {
    0: (
      <Input
        label={
          <RequiredFieldComponent
            isRequired={false}
            labelText={t(
              'transactionsManagement.transactions.list.filters.transactionId'
            )}
          />
        }
        labelFontClassName="text-xs font-medium"
        labelColorClassName="text-back"
        error={errors.id}
        inputProps={{
          ...register('id', {
            validate: {
              uuidValidation: (id: string) =>
                id === null ||
                id?.length === 0 ||
                /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/i.test(
                  id
                ),
            },
          }),
        }}
      />
    ),
    1: (
      <FilterSelect
        label="Api Group"
        name="apiGroup"
        control={control}
        loadOptions={apiGroupList}
        isClearable={false}
      />
    ),
    2: (
      <Input
        label={
          <RequiredFieldComponent isRequired={false} labelText="Event Id" />
        }
        labelFontClassName="text-xs font-medium"
        className="h-8 text-xs font-bold rounded"
        inputProps={{
          ...register('eventId'),
        }}
      />
    ),
    3: hasMerchantList ? (
      <FilterSelect
        label="Merchants"
        name="merchantsIds"
        control={control}
        loadOptions={merchantList}
        isClearable={false}
      />
    ) : null,
    4: (
      <Input
        label={
          <RequiredFieldComponent isRequired={false} labelText="Terminal Id" />
        }
        labelFontClassName="text-xs font-medium"
        className="h-8 text-xs font-bold rounded"
        inputProps={{
          ...register('terminalId'),
        }}
      />
    ),
    5: (
      <FilterSelect
        label="Event Type"
        name="eventType"
        control={control}
        loadOptions={auditEventType}
        isClearable={false}
      />
    ),
    6: (
      <Input
        label={
          <RequiredFieldComponent
            isRequired={false}
            labelText="Card Holder Ip"
          />
        }
        labelFontClassName="text-xs font-medium"
        className="h-8 text-xs font-bold rounded"
        inputProps={{
          ...register('cardHolderIp'),
        }}
      />
    ),
    7: (
      <DatePickerControl
        control={control}
        label={t(
          'transactionsManagement.transactions.list.filters.transactionDate'
        )}
        handleChange={handleDateChange}
        datePickerType={DatePickerType.STANDARD_DATE_SELECT_ONE_MONTH}
        popoverWidth={260}
        placementPosition="bottom-end"
        name="transactionDate"
        maxDate={new Date()}
        footer={<></>}
        title={t(
          'transactionsManagement.transactions.list.filters.transactionDate'
        )}
      />
    ),
    8: (
      <Input
        label={<RequiredFieldComponent isRequired={false} labelText="Amount" />}
        labelFontClassName="text-xs font-medium"
        className="h-8 text-xs font-bold rounded"
        type="number"
        min={0.0}
        step={0.01}
        inputProps={{
          ...register('amount'),
        }}
      />
    ),
    9: (
      <Input
        label={
          <RequiredFieldComponent isRequired={false} labelText="Reference" />
        }
        labelFontClassName="text-xs font-medium"
        className="h-8 text-xs font-bold rounded"
        inputProps={{
          ...register('reference'),
        }}
      />
    ),
  };

  return (
    <FilterObjectWrapper
      filterFields={filterComponentsList}
      onApply={handleSubmit(handleApplyFilters)}
      onClear={resetFilter}
      isDisabledClear={!apiHasFilter}
      isDisabledApply={!apiHasFilter}
    />
  );
};
