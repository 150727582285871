import Label from './Label';
import { getInputProps, InputProps } from './utils';
import React from 'react';
import classNames from 'classnames';
import { CircularProgress, Link } from '@mui/material';

export const Input: React.FC<InputProps> = ({
  label,
  subLabel,
  subLinkLoading,
  subLabelLink,
  isActiveLink,
  disabled = false,
  labelFontClassName,
  labelColorClassName,
  icon,
  name,
  error,
  hasError,
  type = 'text',
  placeholder = ' ',
  className,
  maxLength,
  onKeyPress,
  onChange,
  inputProps,
  defaultValue,
  min,
  max,
  iconTop = 'top-0',
  step,
  isBottomError,
}) => {
  const props = {
    ...getInputProps({
      type,
      error,
      hasError,
      className,
      placeholder,
      disabled,
      icon,
    }),
  };

  return (
    <Label
      name={name}
      label={label}
      labelFontClassName={labelFontClassName}
      labelColorClassName={labelColorClassName}
      error={error}
      isBottomError={isBottomError}
    >
      <div className="relative">
        <input
          type={type}
          defaultValue={defaultValue}
          maxLength={maxLength}
          onChange={onChange}
          min={min}
          max={max}
          step={step}
          onKeyPress={onKeyPress}
          disabled={disabled}
          {...props}
          {...inputProps}
        />
        {icon && (
          <span
            className={classNames(
              'absolute',
              iconTop,
              'right-3 flex items-center justify-center w-12 h-full rounded-lg'
            )}
          >
            {icon}
          </span>
        )}
      </div>
      {subLabel && (
        <>
          {subLabelLink && isActiveLink ? (
            <Link
              component="animate"
              variant="body2"
              onClick={subLabelLink}
              underline="none"
            >
              <span className="left-0 mt-1 h-full text-xs font-medium text-blue-500 cursor-pointer">
                {subLabel}{' '}
                {subLinkLoading ? <CircularProgress size={10} /> : null}
              </span>
            </Link>
          ) : (
            <span className="left-0 mt-1 h-full text-xs font-medium text-gray-500">
              {subLabel}
            </span>
          )}
        </>
      )}
    </Label>
  );
};
