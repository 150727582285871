import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/store';

import { storeName } from './userSlice';

export const getRoot = (state: RootState) => state[storeName];

export const getMainState = createSelector([getRoot], (state) => state);

export const getItems = createSelector([getRoot], ({ items }) => items);

export const getLoggingPage = createSelector(
  [getRoot],
  ({ loggingPage }) => loggingPage
);

export const getLoggingRowsPage = createSelector(
  [getRoot],
  ({ loggingRowsPerPage }) => loggingRowsPerPage
);

export const getTotalNumber = createSelector(
  [getRoot],
  ({ totalNumber }) => totalNumber
);

export const getLoggingDetails = createSelector(
  [getRoot],
  ({ loggingDetails }) => loggingDetails
);

export const getLoggingTotalNumber = createSelector(
  [getRoot],
  ({ logsTotalNumber }) => logsTotalNumber
);

export const isLoggingDetailsLoading = createSelector(
  [getRoot],
  ({ loggingDetailsLoading }) => loggingDetailsLoading
);

export const isUserDetailLoading = createSelector(
  [getRoot],
  ({ isDetailsLoading }) => isDetailsLoading
);
export const userNotFound = createSelector(
  [getRoot],
  ({ itemNotFound }) => itemNotFound
);

export const getPagination = createSelector(
  [getRoot],
  ({
    items,
    page,
    endReached,
    rowsPerPage,
    paginationLoading,
    itemsLoading,
  }) => ({
    total: items.length,
    page,
    endReached,
    rowsPerPage,
    paginationLoading,
    itemsLoading,
  })
);
export const getLoggingPagination = createSelector(
  [getRoot],
  ({
    loggingDetails,
    loggingPage,
    loggingEndReached,
    loggingRowsPerPage,
    paginationLoggingDetailsLoading,
    loggingDetailsLoading,
  }) => ({
    total: loggingDetails.length,
    page: loggingPage,
    endReached: loggingEndReached,
    rowsPerPage: loggingRowsPerPage,
    paginationLoading: paginationLoggingDetailsLoading,
    itemsLoading: loggingDetailsLoading,
  })
);

export const getItemsLoadingComputed = createSelector(
  [getRoot],
  ({ itemsLoading }) => itemsLoading
);
export const getFilters = createSelector([getRoot], ({ filters }) => filters);
export const getAppliedFilters = createSelector(
  [getRoot],
  ({ appliedFilters }) => appliedFilters
);
