import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@mui/icons-material/Info';
import { Popover } from '@mui/material';
import { HintComponent } from 'domain/auth/components/HintComponent';

export const PermissionHintInfo = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  const handlePopoverOpen = (event: MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      {t('userManagement.roleDetails.permissions')}
      <InfoIcon
        fontSize="medium"
        className="ml-2"
        color="primary"
        aria-describedby={id}
        onClick={handlePopoverOpen}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className="m-5">
          <HintComponent>
            {t('userManagement.roleDetails.defaultPermissions')}
          </HintComponent>
        </div>
      </Popover>
    </>
  );
};
