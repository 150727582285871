import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch } from 'hooks/redux';
import { CreatePayment } from './virtual-terminal-component/components/create-payment/CreatePayment';
import { PreviewDetails } from './authorizations/list/component/PreviewDetails';
import { Container as PaymentList } from './virtual-terminal-component/list/Container';
import { resetAllItems } from './virtual-terminal-component/virtualTerminalSlice';

export const VirtualTerminalRout = () => {
  const dispatch = useAppDispatch();
  const match = useRouteMatch();

  useEffect(
    () => () => {
      dispatch(resetAllItems());
    },
    [dispatch]
  );

  return (
    <Switch>
      <Route path={`${match.path}/create`}>
        <CreatePayment />
      </Route>
      <Route path={`${match.path}/details/:id`}>
        <PreviewDetails />
      </Route>
      <Route path={`${match.path}`}>
        <PaymentList />
      </Route>
    </Switch>
  );
};
