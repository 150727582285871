export const IconBankAccount = () => (
  <img
    style={{
      width: '30px',
      height: '30px',
    }}
    src={require('../img/bank-account.png')}
    alt={''}
  />
);
