import { createSlice } from '@reduxjs/toolkit';
import { ReactNode } from 'react';

import { ACCESS_TOKEN_HEADER_KEY } from 'constants/accessTokenHeaderKey';

import {
  checkResetPasswordToken,
  fetchMenuItems,
  fetchProfile,
  forgotPassword,
  login as signIn,
  logout,
  sendPasswordOTP,
  verifyCodeAuth,
} from './thunks';
import moment from 'moment-timezone';
import { dateFormatUtil } from '../../components/dateTimeFormatUtil';

export type Profile = {
  id: string;
  firstName: string;
  lastName: string;
  merchant: boolean;
  role: string;
  dateTimeZone: any;
  dateTimeFormat: any;
  permissions: string[];
};

type MenuItem = {
  label: string;
  icon: ReactNode;
  route: string;
  subitems?: MenuSubItem[];
};

type MenuSubItem = {
  label: string;
  route: string;
};

export const storeName = 'account';

export interface AccountState {
  sidebar: MenuItem[];
  firstMenuItem: string;
  isLoading: boolean;
  isPasswordOTPLoading: boolean;
  profile: Profile | null;
  authenticated: boolean;
  isLoadingSidebar: boolean;
  invalidToken: boolean;
  hasNotifications: boolean;
}

const initialState: AccountState = {
  profile: null,
  isLoading: false,
  isPasswordOTPLoading: false,
  sidebar: [],
  firstMenuItem: '',
  authenticated: false,
  invalidToken: false,
  isLoadingSidebar: false,
  hasNotifications: false,
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    login(
      state,
      action: {
        payload: {
          profile: Profile;
          accessToken: string;
          authenticated: boolean;
        };
      }
    ) {
      state.profile = action.payload.profile;

      sessionStorage.setItem(
        ACCESS_TOKEN_HEADER_KEY,
        action.payload.accessToken
      );
    },
    setProfile(state, action: { payload: Profile | null }) {
      state.profile = action.payload;
      if (!action.payload) {
        state.authenticated = false;
      }
    },
    setFirstMenuItem(state, action) {
      state.firstMenuItem = action.payload;
    },
    setNotificationNumber(state, action) {
      state.hasNotifications = action.payload > 0;
    },
    setDateTimeZone(state, action) {
      if (state.profile) {
        state.profile = { ...state.profile, dateTimeZone: action.payload };
      }
    },
    setDateTimeFormat(state, action) {
      if (state.profile) {
        state.profile = { ...state.profile, dateTimeFormat: action.payload };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.profile = action.payload.profile;
      state.authenticated = action.payload.authenticated;
      sessionStorage.setItem(
        ACCESS_TOKEN_HEADER_KEY,
        action.payload.accessToken
      );
      state.isLoading = false;
    });

    builder.addCase(signIn.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(signIn.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(verifyCodeAuth.fulfilled, (state, action) => {
      state.profile = action.payload.profile;
      state.authenticated = action.payload.authenticated;
      sessionStorage.setItem(
        ACCESS_TOKEN_HEADER_KEY,
        action.payload.accessToken
      );
      state.isLoading = false;
    });

    builder.addCase(verifyCodeAuth.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(verifyCodeAuth.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(fetchProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
      if (state.profile !== null && !state.profile?.dateTimeFormat) {
        state.profile = { ...state.profile, dateTimeFormat: dateFormatUtil };
      }
      if (state.profile !== null && !state.profile?.dateTimeZone) {
        state.profile = { ...state.profile, dateTimeZone: moment.tz.guess() };
      }
      state.isLoading = false;
    });

    builder.addCase(logout.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(logout.rejected, (state) => {
      state.profile = null;
      state.authenticated = false;
      sessionStorage.removeItem(ACCESS_TOKEN_HEADER_KEY);
      state.isLoading = false;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.profile = null;
      state.authenticated = false;
      sessionStorage.removeItem(ACCESS_TOKEN_HEADER_KEY);
      state.isLoading = false;
    });

    builder.addCase(checkResetPasswordToken.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(checkResetPasswordToken.rejected, (state) => {
      state.isLoading = false;
      state.invalidToken = true;
    });
    builder.addCase(checkResetPasswordToken.fulfilled, (state) => {
      state.isLoading = false;
      state.invalidToken = false;
    });
    builder.addCase(sendPasswordOTP.pending, (state) => {
      state.isPasswordOTPLoading = true;
    });
    builder.addCase(sendPasswordOTP.rejected, (state) => {
      state.isPasswordOTPLoading = false;
    });
    builder.addCase(sendPasswordOTP.fulfilled, (state) => {
      state.isPasswordOTPLoading = false;
    });
    builder.addCase(fetchProfile.pending, (state) => {
      state.isLoading = true;
      state.invalidToken = false;
    });

    builder.addCase(fetchProfile.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(forgotPassword.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(forgotPassword.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(forgotPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchMenuItems.fulfilled, (state, action) => {
      state.sidebar = action.payload;
      state.isLoadingSidebar = false;
    });
    builder.addCase(fetchMenuItems.pending, (state) => {
      state.isLoadingSidebar = true;
    });

    builder.addCase(fetchMenuItems.rejected, (state) => {
      state.isLoadingSidebar = false;
    });
  },
});

export const {
  login,
  setProfile,
  setDateTimeZone,
  setDateTimeFormat,
  setFirstMenuItem,
  setNotificationNumber,
} = accountSlice.actions;

export default accountSlice.reducer;
